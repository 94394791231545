<i18n>
ru:
  birthdayGiftTermsShort: ''
  changeButton: Изменить
  chooseButton: Выбрать
  closeButton: Закрыть
  compositionTitle: 'Состав: '
  giftsAreNotAvailableError: Возникла ошибка, попробуйте еще раз позже.
  popupTitle: Подарки ко дню рождения
  selectedButton: Выбран
ua:
  birthdayGiftTermsShort: ''
  changeButton: Змінити
  chooseButton: Вибрати
  closeButton: Закрити
  compositionTitle: 'Склад:'
  giftsAreNotAvailableError: Виникла помилка, спробуйте ще раз пізніше
  popupTitle: Подарунки до дня народження
  selectedButton: Вибрано
us:
  birthdayGiftTermsShort: ''
  changeButton: Change
  chooseButton: Choose
  closeButton: Close
  compositionTitle: 'Ingredients:'
  giftsAreNotAvailableError: An error has occurred. Try again later.
  popupTitle: Birthday presents
  selectedButton: Chosen
</i18n>

<template>
  <div id="birthday-gift-popup">
    <div
      v-if="birthdayIsVisible === true"
      class="birthday-gift-popup"
    >
      <div class="birthday-gift-popup-content">
        <div class="v-cart-birthday-gift-head v-title">
          <h3
            class="title"
            v-html="translate('birthdayGiftPopup.popupTitle')"
          />
        </div>
        <div class="v-cart-birthday-gift-subtitle">
          <span
            class="v-cart-birthday-gift-subtitle-text"
            v-html="translate('birthdayGiftPopup.birthdayGiftTermsShort')"
          />
        </div>
        <div class="v-cart-birthday-scroll-container v-scrollbar">
          <!-- Display birthday-gift -->
          <ul
            v-if="giftsList"
            class="birthday-gift-list"
          >
            <li
              v-for="gift in giftsList"
              :key="sanitize(gift.ProductID)"
              class="item option-container radio-button"
              :class="{
                selected: selectedGift !== null && selectedGift.ProductID === gift.ProductID
              }"
            >
              <div class="img-with-btn-mobile">
                <arora-nuxt-image
                  :alt="gift.Product.Name"
                  :image="gift.Product.SmallImageURL"
                  image-type="ProductSmall"
                />
              </div>

              <div class="item-description">
                <h4
                  class="name"
                  v-html="sanitize(gift.Product.Name)"
                />
                <p
                  v-if="gift.Product.Description"
                  class="composition"
                >
                  <b v-html="translate('birthdayGiftPopup.compositionTitle')" />&nbsp;<span
                    v-html="sanitize(gift.Product.Description)"
                  />
                </p>
              </div>
              <arora-button
                v-if="selectedGift === null || selectedGift.ProductID !== gift.ProductID"
                class-name="profile-birthday-select-gift-select"
                :label="
                  translate(
                    selectedGift === null
                      ? 'birthdayGiftPopup.chooseButton'
                      : 'birthdayGiftPopup.changeButton'
                  )
                "
                @click="async () => await addToCartLocal(gift)"
              />
              <span
                v-else
                class="v-btn profile-birthday-select-gift-select selected"
                v-html="translate('birthdayGiftPopup.selectedButton')"
              />
            </li>
          </ul>
        </div>
        <arora-button
          class-name="btn-default btn-red v-cart-birthday-btn-close"
          :label="translate('birthdayGiftPopup.closeButton')"
          @click="async () => await close()"
        />
      </div>
    </div>

    <div
      v-if="birthdayIsVisible === false"
      class="birthday-gift-popup-error"
      v-html="translate('birthdayGiftPopup.giftsAreNotAvailableError')"
    />
  </div>
</template>

<script setup lang="ts">
import type { PresentInfo } from '~types/accountStore'
import type { CartItem } from '~types/clientStore'

import { FirstAddType } from '~api/consts'

const accountStore = useAccountStore()
const clientStore = useClientStore()
const popupStore = usePopupStore()

const { sanitize, translate } = useI18nSanitized()
const { addToCart, removeCartItem } = useCartFunctionsWithDialogs()

const birthdayIsVisible = computed(() => {
  const birthdayData = accountStore.BirthdayGifts.data

  if (birthdayData === null) {
    return false
  }

  return (
    birthdayData.BirthdayGiftIsEnabled &&
    birthdayData.ClientDataFilled &&
    birthdayData.InBirthdayPeriod &&
    !birthdayData.AnnualLimitReached
  )
})

const selectedGift = computed<CartItem | null>(() => {
  const gifts = accountStore.BirthdayGifts.data?.Presents ?? null

  if (gifts === null) {
    return null
  }

  return (
    clientStore.ClientState?.data?.Cart?.Content?.find((item: CartItem) => {
      return gifts && gifts.some((gift: PresentInfo) => gift.ProductID === item.ProductID)
    }) ?? null
  )
})

const giftsList = computed<PresentInfo[] | null>(() => {
  const presents = accountStore.BirthdayGifts.data?.Presents
  if (presents) {
    return presents.filter((present: PresentInfo) => {
      return present.Product && present.Product.Active && !present.Product.InStopList
    })
  }

  return null
})

async function close(): Promise<void> {
  await popupStore.closePopup()
}

async function deleteGift(): Promise<void> {
  if (selectedGift.value !== null) {
    await removeCartItem(
      selectedGift.value.ID,
      selectedGift.value.Count,
      selectedGift.value.GroupID,
      selectedGift.value.Name
    )
  }
}

async function addToCartLocal(gift: PresentInfo): Promise<void> {
  await deleteGift()

  await addToCart(
    gift.Product.ID,
    FirstAddType.Default,
    gift.Product.GroupID,
    gift.Product.Name,
    gift.Product.Price
  )
}
</script>

<style lang="scss" scoped>
@use '~/assets/variables';

.v-cart-birthday-gift-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;

  .title {
    margin: 0;
    color: variables.$PopupColor;
  }
}

.v-cart-birthday-gift-subtitle {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 25px;

  &-text {
    color: variables.$PopupColor;
  }
}

.v-cart-birthday-scroll-container {
  max-height: 450px;
  overflow-y: scroll;
  padding-right: 20px;
  margin-right: -25px;
  margin-left: -5px;
  padding-left: 5px;

  .birthday-gift-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    padding-left: 10px;

    .item {
      flex: 0 0 33.3%;
      max-width: 33.3%;
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      color: variables.$PopupColor;
      margin-bottom: 10px;
      border: none;

      .img-with-btn-mobile {
        .img-fluid {
          max-width: 100%;
          height: auto;
          margin-bottom: 5px;
          border-radius: variables.$BorderRadius variables.$BorderRadius variables.$BorderRadius 0;
        }

        .v-btn {
          display: none;
        }
      }

      &-description {
        border: variables.$BorderWidth dashed rgba(0, 0, 0, 0.18);
        border-radius: 0 variables.$BorderRadius variables.$BorderRadius;
        padding: 16px;
        width: 100%;
        flex-grow: 1;

        .name {
          font-size: variables.$TextSizeMain;
          font-weight: bold;
          min-height: 35px;
          margin-bottom: 5px;
        }

        .composition {
          font-size: 12px;
          margin-bottom: 20px;
        }
      }

      .v-btn {
        margin: -24px auto 0;

        input {
          display: none;
        }

        label {
          margin: 0;
          cursor: pointer;
        }
      }

      .profile-birthday-select-gift-select {
        font-size: variables.$TextSizeMain;
        padding: 10px 20px;
        background: variables.$PrimaryBackgroundColor;
        color: variables.$PrimaryColor;

        &.selected {
          background: variables.$SuccessColor;
          color: variables.$SuccessForeColor;
          border-color: variables.$SuccessColor;
          text-align: center;
          border-radius: 4px;
        }

        &:hover {
          cursor: pointer;
          background: variables.$SecondaryBackgroundColor;
          color: variables.$SecondaryColor;
        }
      }
    }
  }
}

.v-cart-birthday-btn-close {
  width: max-content;
  display: none;
}

@media screen and (max-width: 767px) {
  .birthday-gift-popup {
    padding: 0 15px;

    &-content {
      width: 100%;
      padding: 16px;
      border-radius: 12px;

      .v-cart-birthday-gift-head {
        display: block;
        margin-bottom: 10px;

        .close {
          display: block;
          margin-bottom: 10px;
          float: none;
          text-align: right;
        }
      }

      .v-cart-birthday-scroll-container {
        max-height: 350px;
        padding-right: 11px;
        margin-right: -13px;

        .birthday-gift-list {
          margin: 0;

          .item {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0;
            border: variables.$BorderWidth dashed rgba(0, 0, 0, 0.18);
            border-radius: 12px 0 12px 12px;

            .img-with-btn-mobile {
              display: flex;
              align-items: center;
              padding: 16px;

              .img-fluid {
                margin-bottom: 0;
                flex: 0 0 60%;
                max-width: 60%;
                padding-right: 36px;
                border-radius: 12px 0 12px 12px;
              }

              .v-btn {
                display: block;
                flex: 0 0 40%;
                max-width: 40%;
                margin: 0;
              }
            }

            &-description {
              border: none;
              padding: 0 16px 16px;

              .name {
                font-size: 16px;
                min-height: auto;
              }

              .composition {
                font-size: variables.$TextSizeMain;
                margin-bottom: 0;
              }
            }

            .v-btn {
              margin: auto;
            }
          }
        }
      }

      .v-cart-birthday-btn-close {
        display: block;
        margin: 24px auto 6px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .birthday-gift-popup {
    &-content {
      .v-cart-birthday-scroll-container {
        .birthday-gift-list {
          .item {
            .img-with-btn-mobile {
              flex-wrap: wrap;
              justify-content: center;

              .img-fluid {
                flex: 0 0 55%;
                max-width: 55%;
                padding-right: 12px;
              }

              .v-btn {
                flex: 0 0 45%;
                max-width: 45%;
                padding: 10px 16px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
